import { Spinner, Text, Flex, Spacer, Box } from '@chakra-ui/react';
import MiniStatistics from 'lib/horizon/components/card/MiniStatistics';
import { useSelectedUsers } from 'lib/coplay/components/DataProvider';
import NumUsers from "lib/coplay/components/views/NumUsers";
import { AddNewActionButton } from '../../actions/user/AddNewActionButton';

export default function DashboardInfo(props: { isRefreshing: boolean, numDisplayedUsers: number }) {
    const selectedUsers = useSelectedUsers();

    console.log('Rendering DashboardInfo');

    const renderRefreshMessage = () => {
        if (props.isRefreshing) {
            return (
                <Flex alignItems='center' >
                    <Spinner px={'10px'} />
                    <Spacer />
                    <Text > Auto Refreshing users...</Text>
                </Flex>
            )
        }
    }

    return (
        <Flex
            alignItems='center'
            justifyContent='left'
            gap='20px'
            mb='20px'
            flexWrap='wrap'
        >
            <NumUsers />
            <Box maxWidth="300px">
                <MiniStatistics
                    name='Selected Users'
                    value={selectedUsers.size()}
                />
            </Box>
            <Box maxWidth="300px">
                <MiniStatistics
                    name='Displayed Users'
                    value={props.numDisplayedUsers}
                />
            </Box>
            <Spacer />
            <AddNewActionButton />
            {renderRefreshMessage()}

        </Flex>
    );
}
import { Flex, Progress, Text } from "@chakra-ui/react";
import { StatusButton } from "./StatusButton";
import { useEffect, useState } from "react";
import { XboxUser, UserStatusMessage, UserStatus } from "lib/coplay/types/BackendTypes";


function UserStatusText(props: { user: XboxUser, onClick: () => void }) {
    return (
        <Text onClick={() => { props.onClick() }} pr="1" width="100%" maxH="60px" fontWeight='bold' fontSize='sm' color="green" whiteSpace="normal" overflow="hidden" textOverflow="ellipsis">
            {props.user.presenceText}<br />
        </Text>
    );
}

function StatusContainer(props: { children: React.ReactNode }) {
    return (
        <Flex width="150px" alignItems="center" justifyContent="center">
            {props.children}
        </Flex>
    );
}

export function UserStatusRenderer(props: { user: XboxUser, onClick: () => void }) {
    const status = props.user.userStatus.statusMessage

    if (status.includes(UserStatusMessage.Error) || status.includes(UserStatusMessage.Warning)) {
        return (
            <StatusContainer>
                <StatusButton rowData={props.user} />
                <UserStatusText user={props.user} onClick={() => { props.onClick() }} />
            </StatusContainer>
        );
    } else if (!status.includes(UserStatusMessage.Ready)) {
        return (<InProgressUserStatus status={props.user.userStatus} />);
    }

    return (
        <StatusContainer>
            <UserStatusText user={props.user} onClick={() => { props.onClick() }} />
        </StatusContainer>
    )
}


export function InProgressUserStatus(props: { status: UserStatus }) {
    const [progress, setProgress] = useState(0);
    const statusMessage = props.status.statusMessage;
    const nextAttempt = props.status.nextRequest;
    const startTime = props.status.lastAction.requestID

    useEffect(() => {
        if (nextAttempt && startTime && !statusMessage.includes(UserStatusMessage.Ready)) {
            const interval = setInterval(() => {
                const currentTime = new Date().getTime();
                const totalTime = nextAttempt - startTime;
                const timeElapsed = currentTime - startTime;
                let newProgress = (timeElapsed / totalTime) * 100;

                newProgress = Math.max(0, Math.min(newProgress, 100));
                setProgress(newProgress);
            }, 2500);

            return () => clearInterval(interval);
        }
    }, [nextAttempt, startTime, statusMessage]);

    if (!statusMessage.includes(UserStatusMessage.Ready)) {
        return (
            <div>
                <div>
                    <Text width="150px" fontWeight='bold' fontSize='sm'>{statusMessage}</Text>
                </div>
                <div>
                    {nextAttempt && <Progress value={progress} size='xs' />}
                </div>
            </div>
        );
    }

    return null;
}
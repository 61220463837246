// Chakra imports
import { Text, Box } from '@chakra-ui/react';
// Layout components
import AppNavBar from 'lib/coplay/components/app_layout/AppNavBar';
import { ContentArea } from 'lib/coplay/components/app_layout/ContentArea';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { AppSidebar } from 'lib/coplay/components/app_layout/AppSidebar';
import Brand from 'lib/horizon/components/sidebar/components/Brand';
import AccountView from 'lib/coplay/components/views/account/AccountView';
import UsageView from 'lib/coplay/components/views/usage/UsageView';
import Dashboard from 'lib/coplay/components/views/dashboard/Dashboard';
import { ProfilesView } from './views/profiles/ProfilesView';
import SchedulesView from './views/schedules/SchedulesView';
import APIView from './views/api/APIView';
import NotificationsView from './views/notifications/NotificationsView';
import { XMNotificationProvider } from '../context/NotificationContext';
import { PaymentsSidebarSection } from './sidebar/PaymentsSidebarSection';
import { SettingsSidebarSection } from './sidebar/SettingsSidebarSection';
import PaymentsView from './views/payments/PaymentsView';
import { XboxSidebarSection } from './sidebar/XboxSidebarSection';

export default function App(props: { [x: string]: any }) {
    const location = useLocation();
    const isDashboard = location.pathname === '/app/dashboard';

    return (
        <XMNotificationProvider>
            <Box>

                <AppNavBar />

                <AppSidebar>
                    <Brand key="Brand" />
                    <Text my={2} px={4} fontSize={'lg'} fontWeight={'bold'}>Platforms</Text>
                    <Box m={2} px={4} fontSize={'md'} fontWeight={'bold'}>
                        <Text>Xbox Manager</Text>
                    </Box>
                    <XboxSidebarSection isDashboard={isDashboard} />

                    <PaymentsSidebarSection isDashboard={isDashboard} />
                    <SettingsSidebarSection isDashboard={isDashboard} />

                </AppSidebar >

                <ContentArea >
                    <Switch>
                        <Route exact path='/app/payments' component={PaymentsView} />
                        <Route exact path='/app/dashboard' component={Dashboard} />
                        <Route exact path='/app/usage' component={UsageView} />
                        <Route exact path='/app/account' component={AccountView} />
                        <Route exact path='/app/profiles' component={ProfilesView} />
                        <Route exact path='/app/schedules' component={SchedulesView} />
                        <Route exact path='/app/api' component={APIView} />
                        <Route exact path='/app/notifications' component={NotificationsView} />
                        <Route path={"*"}>
                            <Redirect to={"/app/dashboard"} />
                        </Route>
                    </Switch>
                </ContentArea>

            </Box >
        </XMNotificationProvider >
    );
}